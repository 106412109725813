@use "../../../../assets/scss/variables" as v;
@use "../../../../assets/scss/mixins" as m;

.slider{
    .swiper-wrapper{
        .swiper-slide{
            position: relative;
            &::after{
                content: "";
                position: absolute;
                top:0; bottom: 0; right: 0; left:0;
                background-color: v.$color5;
            }

            img{
                object-fit: cover;
                width:100%;
                height: 100vh;
            }

            .content{
                position: absolute;
                top:30%;
                left:50%;
                transform: translate(-50%);
                text-align: center;
                color: v.$color4;
                z-index: 5;

                h2{
                    font-size: 3rem;
                    font-weight: 600;
                    text-shadow: 0 0 30px black;

                }
            }
        }
    }

    .swiper-button-prev, .swiper-button-next{
        color: v.$color2;
    }

    .swiper-pagination-bullet-active{
        background-color: v.$color2;
    }
}