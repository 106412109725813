@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

.admin-navbar{

    @media(min-width: 992px){
        height: 100vh;
        align-items: flex-start !important;
        position: fixed !important;
        top:0;
        left:0;
    }

    .container{
        @media(min-width: 992px){
            flex-direction: column;
        }
    }

    .navbar-nav{
        @media(min-width: 992px){
            flex-direction: column !important;
        }

        .nav-link{
            color: v.$color2;

            &:hover{
                color: v.$color4;
            }
        }

       
    }

   
}
