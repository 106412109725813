@use "../../../../assets/scss/variables" as v;

.section-header{
    text-align: center;

    h2{
        font-weight: 400;
        font-size: 3rem;
        color: v.$color2;
        text-transform: uppercase;

        span{
            font-weight: 800;
        }
    }

    p{
        color: v.$color1;
    }
}